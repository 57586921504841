import * as rest from 'common/rest'

export async function getVerificationCode (phone) {
  if (!phone) return false
  const data: any = {
    phone
  }

  try {
    const res = await fetch('/api/user/sms/send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`)
    }

    const result = await res.json()
    return result;
  } catch (err) {
    throw new Error(`短信验证码请求出错: ${err.message}`)
  }
}
