import * as _ from 'lodash'
import * as utils from 'common/utils'
import config from 'common/config'
import * as React from 'react'
import { useRef, useEffect } from 'react'
import constants from 'common/constants'
import { connect } from 'react-redux'
import { withRouter, Route } from 'react-router-dom'
import * as urlHelper from 'helpers/url'
import * as socketHelper from 'helpers/socket'
import { fetchConfigs } from 'actions/common'
import { fetch as userFetch, signInOrSignUpBySsUser } from 'actions/user'
import { fetchProfile } from 'actions/profile'
import * as Enum from 'common/enum'
import * as rest from 'common/rest'
import fn from 'common/modules'
import { fetchOrganization } from 'actions/organization'
import permission from 'common/permission'
import Header from 'components/common/header'
import StudentHeader from 'components/common/student_header'
import Panel from 'components/common/panel'
import StudentPanel from 'components/student/student_panel'
import Modal from 'components/common/modal'
import ModalForm from 'components/common/modal_form'
import ModalConfirm from 'components/common/modal_confirm'
import PlatformContainer from 'containers/platform/platform'
import OnlinePlayer from 'containers/common/online_player'
import Questionaire from 'containers/common/questionaire'
import SchoolContainer from 'containers/school/school'
import StudentContainer from 'containers/student/student'
import EntranceExam from 'containers/student/entrance_exam'
import StudyPlan from 'containers/school/study_plan'
import Home from 'containers/home'
import MobileModal from 'containers/mobile_modal'
import AutoTrialCourseRequest from 'containers/school/auto_trial_course_request'
import Tip from 'components/common/tip'
import Loading from 'components/common/loading'
import { message } from 'antd'
import { Col, Row } from 'antdv4'
import SideQuestionnaireLink from 'components/common/side_questionnaire_link'
import TimeSlotsModal from 'components/common/time_slots_modal'
import EditionUpdate from 'components/common/edition_update/edition_update'
import __config from '../../../package.json'
import Fingerprint2 from 'fingerprintjs2'
import { isMobile } from 'is-mobile' // TODO: 修改了import方式，需要检查是否能正常工作
import 'actions/socket'
import LangContext from 'helpers/context'
import { loginApi, getSmartToken, setSmartToken } from '../apis/user'
import ChatBox from 'components/common/chat_box'


let sign = utils.getLocalStorage('lang') == 'zh-CN' || !utils.getLocalStorage('lang')
let menus = fn(sign)

const USER_MAP = {
  1: '/student',
  2: '/school',
  3: '/platform'
}

const OLD_ROUTES = [
  {
    path: '/school/teaching-admin/reqs',
    newPath: '/school/teaching-work/arrangement'
  },
  {
    path: '/school/teaching/req/check',
    newPath: '/school/schedule/teaching-req-view'
  },
  {
    path: '/school/student/schedule',
    newPath: '/school/schedule/student-schedule'
  },
  {
    path: '/school/teacher/schedule/mine',
    newPath: '/school/schedule/my-schedule'
  },
  {
    path: '/school/changing-admin/reqs',
    newPath: '/school/teaching-work/changing'
  },
  {
    path: '/school/changing/req/create',
    newPath: '/school/schedule/changing-req-create'
  },
  {
    path: '/school/students/student-list',
    newPath: '/school/transfer/transfer-list'
  },
  {
    path: '/school/transfer/online-transfer-list',
    newPath: '/school/transfer/transfer-list'
  },
  {
    path: '/school/transfers',
    newPath: '/school/transfer/transfer-list'
  },
  {
    path: '/school/teaching/req/create',
    newPath: '/school/schedule/teaching-req-create'
  },
  {
    path: '/school/banke',
    newPath: '/school/schedule/banke'
  },
  // {
  //   path: '/school/preview-course',
  //   newPath: '/school/preview-course',
  // },
]

// 容器
class Container extends React.Component<any, any> {
  query

  timeout = null
  isShowNewVersion = config.showQuestionnaireLink === 'on'

  constructor (props) {
    super(props)
    let modules = null
    this.state = {
      isMobile: isMobile(),
      userTypeId: utils.getUserTypeId(),
      action: '',
      serveType: 1,
      vacationModalVisible: false,
      vacationModalAlertVisible: false,
      count: 0,
      messageUpdate: false,
      alteredRoute: '',
      open: false,
    }
    this.query = {}
    let arr = location.search.substr(1).split('&').map(str => str.split('='))
    arr.forEach(i => (this.query[i[0]] = decodeURIComponent(i[1])))
    let isInsideValue = global.IS_INSIDE
    Object.defineProperty(window, 'IS_INSIDE', {
      get: () => isInsideValue,
      set: () => {}
    })

    const smartToken = this.query.smart_token
    if (smartToken && smartToken !== 'undefined') {
      setSmartToken(smartToken)
    }
  }

  getModules = (nocache = false) => {
    const sign = utils.getLocalStorage('lang') == 'zh-CN' || !utils.getLocalStorage('lang')
    let allFullModules = fn(sign)
    var fullModules: any = utils.cloneObj(allFullModules)
    if (!utils.isLogined()) return []
    const userTypeId = this.state.userTypeId
    if (userTypeId === 1) {
      const studentMenu = fullModules.student
      return studentMenu.filter(item => {
        console.log('------ item will be check', item)
        if (item.show && typeof item.show === 'function') {
          return item.show()
        }
        return true
      })
    }
    if (userTypeId === 3) return fullModules.platform

    var modulePermission = utils.getPermissionModules(nocache)
    if (!modulePermission.length) return []
    let modules = []
    fullModules.school.forEach(menu => {
      if (!menu.children) {
        modulePermission.indexOf(menu.key) > -1 && modules.push(menu)
      } else {
        menu.children = menu.children.filter(
          submenu => modulePermission.indexOf(submenu.key) > -1
        )
        menu.children.length && modules.push(menu)
      }
    })
    return Object.create(modules)
  }

  async UNSAFE_componentWillMount/* eslint-disable-line camelcase */ () {
    // 与智课网保持同登同退逻辑
    const ssUserCookie = utils.getCookie('user', 'ss_')
    const referer = document.referrer || ''
    const host = referer.split('/')[2]
    const isReferrerFromZhike = config.url.www.endsWith(host)
    utils.setRedirect(this.redirect())
    if (ssUserCookie) {
      const ssUser = JSON.parse(ssUserCookie)
      if (!utils.isLogined()) {
        await this.props.dispatch(signInOrSignUpBySsUser(ssUser))
      } else {
        const smartUserId = utils.getOriginUserId()
        const userType = utils.getUserTypeId()
        // 判断是否需要根据ssUser登录。条件为：userId相同，机构为在线学习中心，并且身份类型为学生
        let isSame = (ssUser.id == smartUserId)
        if (isSame) {
          // 判断下是否来自于智课网的跳转。如果是的话，需要完全重置
          if (isReferrerFromZhike) {
            isSame = utils.isOnline() && userType === constants.USER_TYPE.STUDENT
          }
        }
        if (!isSame) {
          await this.props.dispatch(signInOrSignUpBySsUser(ssUser))
        }
      }
    } else {
      const userTypeId = this.state.userTypeId
      if (utils.isLogined()) {
        if (utils.isOnline() && Number(userTypeId) === 1) {
          utils.logout()
        }
      } else {
        if (isReferrerFromZhike) {
          utils.redirect('/')
        }
      }
    }

    // 加载基础配置信息
    this.props.dispatch(fetchConfigs())
    this.props.dispatch(userFetch())
    if (utils.isOnline() && utils.isStudent()) {
      this.props.dispatch(fetchProfile(utils.getUserId()))
    }
    //const isIllegalAccess = this.isIllegalAccess()
    const alteredRoute = this.getAlteredRoute()
    this.setState({ alteredRoute })
  }

  async componentDidMount () {
    const fingerprint: any = await new Promise((resolve) => {
      new Fingerprint2().get((result, components) => resolve({ result, components }))
    })
    utils.setLocalStorage('fingerprint', fingerprint.result)
    utils.setLocalStorage('version', __config.version)
    // 隐藏loading动画
    const elLoading = document.querySelector('.template-loading')
    elLoading.parentNode.removeChild(elLoading)
    // 判断是否为chrome或者firefox浏览器
    // const isChrome = !!window.chrome && !!window.chrome.webstore;
    // const isFirefox = typeof InstallTrigger !== 'undefined';
    // 定义全局函数
    const userTypeId = this.state.userTypeId
    if (utils.isLogined() && +userTypeId !== constants.USER_TYPE.ADMIN) { // 大刷新的时候请求一次organizationInfo
      this.props.dispatch(fetchOrganization())
    }
    utils.setRefresh(this.refresh())
    utils.setCreateModal(this.createModal())
    utils.setCreateModalForm(this.createModalForm())
    utils.setCreateModalConfirm(this.createModalConfirm())
    utils.setCreateTip(this.createTip())
    if (this.isIllegalAccess()) return utils.redirect('/')
    if (this.state.alteredRoute) return utils.redirect(this.state.alteredRoute)
    const body = document.getElementsByTagName('body')[0]
    body.addEventListener('click', this.setLastActTime)
    body.addEventListener('mouseover', this.setLastActTime)
    utils.setLocalStorage('lastActionTime', new Date().getTime())
    // 更新socket身份信息
    socketHelper.updateSocketAuthorization()
    this.checkIlleagleAccess()

    this.handleProps()
  }

  getAlteredRoute = () => {
    const { pathname, search } = this.props.location
    const oldRoute = OLD_ROUTES.find(i => (i.path === pathname || pathname.includes(i.path)))
    if (oldRoute) {
      const endParam = pathname.split('/').pop()
      let someId = ''
      const id = Number(endParam)
      if (!isNaN(id)) {
        someId = String(id)
      }
      return `${oldRoute.newPath}${someId && `/${someId}`}${search}`
    } else {
      return ''
    }
  }

  pushHistory = params => {
    urlHelper.pushHistory(this.props, params)
  }

  //UNSAFE_componentWillReceiveProps/* eslint-disable-line camelcase */ (nextProps) {
  handleProps () {
    const topLevel = (location.pathname.match('^/(.*?)/') || [])[1]
    let isIllegalAccess = this.isIllegalAccess()
    const userTypeId = this.state.userTypeId
    if (topLevel) {
      let modules = userTypeId === 1
        ? utils.getStudentPermissionModules()
        : utils.getModules(true)
      console.log('modules=====', modules)
      this.setState({
        modules,
      })
    }
    if (utils.isOnline() && _.isEmpty(this.props.profile) && utils.isStudent()) {
      this.props.dispatch(fetchProfile(utils.getUserId()))
    }
  }

  componentDidUpdate (prevProps) {
    const oldPathName = prevProps.location.pathname
    const oldSearch = prevProps.location.search
    const { pathname, search } = this.props.location
    if (oldPathName !== pathname || oldSearch !== search) {
      if (global.sa) {
        global.sa.quick('autoTrackSinglePage')
      }
    }
  }

  isIllegalAccess () {
    const whitelist = ['', '/', '/play', '/liuxue', '/uskid', '/uskid2018', '/read-doc', '/study-plan', '/school/schedule/auto-trial-course-request', '/teacher', '/admin', '/student', '/questionaire', '/student/entrance_exam']
    if (whitelist.indexOf(location.pathname) > -1) return false
    if (!utils.isLogined()) {
      return true
    }
    const userTypeId = this.state.userTypeId
    return !location.pathname.startsWith(USER_MAP[userTypeId])
  }

  setLastActTime = () => {
    try {
      clearTimeout(this.timeout)
    } catch (e) {
      // ...
    }
    this.timeout = setTimeout(
      () => utils.setLocalStorage('lastActionTime', new Date().getTime()),
      200
    )
  }

  getPanel (modules) {
    const topLevel = (location.pathname.match('^/(.*?)/') || [])[1]
    if (!topLevel || !modules) return <div />
    if (topLevel === 'student') {
      return (
        <StudentPanel
          topLevel={topLevel}
          modules={modules}
          organizations={this.props.organizations}
          pushHistory={this.pushHistory}
          query={this.props.location.query}
        />
      )
    }
    return (
      <LangContext.Consumer>{
        (val) => {
          return <Panel
            topLevel={topLevel}
            modules={modules}
            location={this.props.location}
          />
        }
      }</LangContext.Consumer>
    )
  }

  handleGetAbsence = async () => {
    await this.props.dispatch(fetchProfile(utils.getUserId()))
  }

  getSideLink () {
    const topLevel = (location.pathname.match('^/(.*?)/') || [])[1]
    if (topLevel === 'student') {
      return <SideQuestionnaireLink
        isShowNewVersion={this.isShowNewVersion}
      />
    }
    return null
  }

  handleChangeSite = async id => {
    if (Number(id) === utils.getOrganizationId()) return false
    const res = await loginApi({ siteId: id })
    if (res.code) return message.error(res.msg)
    // 写权限信息
    utils.setLocalStorage('permissionModules', res.data.permissionModules)
    utils.setLocalStorage('permissionOperations', res.data.permissionOperations)
    utils.setLocalStorage('visibleOrganizations', res.data.visibleOrganizations)
    utils.setLocalStorage('isTeacherSupervisor', !!res.data.isTeacherSupervisor)
    utils.setCookie('crossTeacherVisible', res.data.crossTeacherVisible ? 1 : 0, -1, '', '')
    this.props.dispatch(userFetch())
    utils.removePermissionModules()
    utils.resetFullModules()

    this.tryToRedirectToSameModule(id)
  }

  tryToRedirectToSameModule = id => {
    const userTypeId = this.state.userTypeId
    if (+userTypeId === 2) {
      const path = location.pathname.slice(1).split('/')
      const orgType = utils.isHeadquarter(id) ? 3 : (utils.isOnline(id) ? 2 : 1)
      let target = menus[path[0]].find(item => item.path === path[1]) || {}

      if (path[2] && target.children) {
        target = target.children.find(item => item.path === path[2])
      }

      if (target && permission[target.key] && permission[target.key].orgType.includes(orgType)) {
        return utils.redirect(`/?redirecturl=${location.pathname}`)
      }
    }

    utils.redirect('/')
  }

  getCount = async () => {
    const url = `${config.url.im}/messages?token=${utils.getToken()}&status=1`
    const res = await rest.get(url)
    if (res.code) return message.error('获取消息个数失败')
    this.setState({
      count: res.data.count
    })
  }

  watchMessage = async () => {
    window.open(`${config.url.www}/user/im.html`)
    this.setState({
      count: 0,
      messageUpdate: false
    })
  }

  showMessage = async () => {
    this.setState({
      messageUpdate: true
    })
  }

  closeMessage = async () => {
    this.setState({
      messageUpdate: false
    })
  }

  setMyState (newState) {
    this.setState(newState)
  }

  renderHome () {
    return <Route path='/' render={props => <Home
      {...props}
      changeParentState={this.setMyState.bind(this)}
      params={props.match.params}
    />} />
  }

  renderReportPreview () {
    const imgArr = []
    const IMAGES_NUM = 7
    for (let i = 0; i < IMAGES_NUM;) {
      imgArr.push(<img src={require(`images/report-preview${++i}.jpg`)} />)
    }
    return <div className='report-preview-container'>
      {/* TODO: 减少不必要的数据请求 */}
      {imgArr}
    </div>
  }

  renderPlayer () {
    return (
      <div className='container-student-online-player'>
        <Route
          path='/play'
          render={props => <OnlinePlayer
            {...props}
            params={props.match.params}
          />}
        />
        {this.state.modal}
      </div>)
  }

  renderQuestionaire () {
    return (
      <div className='questionaire-wrapper'>
        <Route
          path='/questionaire'
          render={props => <Questionaire {...props} />}
        />
      </div>
    )
  }

  renderStudyPlan () {
    return (
      <div>
        <Route path='/school/study/study-plan/:studentId/:studentName?' render={props => <StudyPlan
          {...props}
          params={props.match.params}
        />} />
        {this.state.modal}
      </div>
    )
  }

  renderSmartAdaptation () {
    const qs = new URLSearchParams(this.props.location.search)
    qs.set('smart_token', getSmartToken())
    location.href = `${config.adaptationHost}${this.props.location.pathname}?${qs.toString()}`
    return ''
  }

  renderEntranceExam () {
    return (
      <div className='entrance-exam-wrapper'>
        <Route
          path='/student/entrance_exam'
          render={props => <EntranceExam {...props} />}
        />
      </div>
      )
  }

  renderAutoTrialCourseRequest () {
    return (
      <Route path='/school/schedule/auto-trial-course-request' render={props => <AutoTrialCourseRequest
        {...props}
        params={props.match.params}
      />} />
    )
  }

  toggleChatBox () {
    this.setState({
      open: !this.state.open
    }, () => {
      if (this.state.open) {
        setTimeout(() => {
          window.scrollTo({
            left: document.documentElement.scrollWidth,
            behavior: 'smooth'
          });
        }, 100); 
      }
    })
  }

  async onUserInputChange (e) {
    this.setState({
      userInput: e.target.value
    })
  }

  getChatBox () {
    if (!this.state.open) return ""
    /*
    const i = utils.getQueryParams()['i']
    if (!i){
      return (<ChatBox />)
    }
    */
    let userTypeId = Number(utils.getCookie('userTypeId'))
    const role = userTypeId == 1 ? 's' : 't'
    const url = `${config.aiSuperTeacherUrl}?r=${role}`

    return (
      <div className="ai-superteacher-wrapper" style={{paddingTop:'58px', width:'100%', height:'100%'}}>
        <iframe
          title="ai-superteacher"  // 必填（可访问性要求）
          src={url}
          width="100%"
          height="100%"
          frameBorder="0"          // React 中需使用驼峰命名
          allowFullScreen          // 全屏权限
          allow="microphone"
          sandbox="allow-scripts allow-same-origin allow-top-navigation allow-popups allow-forms" // 安全沙箱
        />
      </div>
    )
  }

  renderStudent (modules) {
    if (utils.isOnline() && _.isEmpty(this.props.profile)) return <Loading />
    const isAbsencing = utils.isOnline() ? this.props.profile.data.isAbsencing : null
    const topLevel = (location.pathname.match('^/(.*?)/') || [])[1]
    const chatBoxWidth = this.state.open ? "600px" : "0px"
    const chatBox = this.getChatBox()
    const showRobotIcon = utils.getCookie('showRobotIcon')
    return (
      <div
        className='container-index student-container-index'
      >
        <div className='main-content'>
          {this.getPanel(modules)}
          <Row style={{display: "flex", height: "100%"}}>
            <Col flex="auto">
              <div className='student-page'>
                <div className='student-header'>
                  <StudentHeader
                    topLevel={topLevel}
                    params={this.props.params}
                    organizations={this.props.organizations}
                    modules={modules}
                    isAbsencing={isAbsencing}
                    profile={this.props.profile}
                    watchMessage={this.watchMessage}
                    getCount={this.getCount}
                    count={this.state.count}
                    onChangeSite={this.handleChangeSite}
                    pathname={this.props.location.pathname}
                  />
                </div>
                <div className='student-body'>
                  <Route path='/student' component={StudentContainer} />
                </div>
                {showRobotIcon ? <div className='ai-chatbox-trigger' onClick={this.toggleChatBox.bind(this)}></div> : null}
              </div>
            </Col>
            <Col flex={chatBoxWidth}>
              {chatBox}
            </Col>
          </Row>
        </div>

        {this.getSideLink()}
        <EditionUpdate
          type={'student'}
          count={this.state.count}
          watchMessage={this.watchMessage}
          closeMessage={this.closeMessage}
          showMessage={this.showMessage}
          messageUpdate={this.state.messageUpdate}
        />
        {this.state.modal}
        {this.state.modalForm}
        {this.state.modalConfirm}
        {this.renderTimeSlotsModal()}
        {this.state.tip}
      </div>
    )
  }

  renderTimeSlotsModal = () => {
    const { timeSlots, organizations } = this.props
    if (!timeSlots) {
      return null
    } else {
      const organizationId = utils.getOrganizationId()
      const currentOrganization = organizations.list.find(org => org.id === organizationId)
      if (currentOrganization && currentOrganization.slotsData) {
        return <TimeSlotsModal isView timeSlots={currentOrganization.slotsData} />
      } else {
        message.error(!currentOrganization ? '无当前站点信息' : '无当前站点时间段信息')
        return null
      }
    }
  }

  // 渲染
  render () {
    window['STATE'] = this.state
    const modules = this.state.userTypeId === 1
        ? utils.getStudentPermissionModules()
        : utils.getModules(true)
    const topLevel = (location.pathname.match('^/(.*?)/') || [])[1]
    if (this.state.isMobile) {
      return <MobileModal handleOk={() => this.setState({ isMobile: false })} />
    }
    if (this.isIllegalAccess()) return false
    if (!this.props.configCompleted) return <Loading />
    if (this.checkIsInHomePage()) {
      return this.renderHome()
    }
    if (this.props.location.pathname.indexOf('/report-preview') !== -1) {
      return this.renderReportPreview()
    }
    if (this.props.location.pathname.indexOf('/questionaire') !== -1) {
      return this.renderQuestionaire()
    }
    if (this.props.location.pathname.indexOf('/play') !== -1) {
      return this.renderPlayer()
    }
    if (this.props.location.pathname.indexOf('student/smart-adaptation-') !== -1 ||
    this.props.location.pathname.indexOf('read-doc') !== -1) {
      return this.renderSmartAdaptation()
    }
    if (this.props.location.pathname.indexOf('study/study-plan') !== -1) {
      return this.renderStudyPlan()
    }
    if (this.props.location.pathname.indexOf('student/smart-adaptation/') !== -1 ||
      this.props.location.pathname === '/student/smart-adaptation') {
      return this.renderSmartAdaptation()
    }
    if (this.props.location.pathname.indexOf('student/entrance_exam') !== -1 ||
      this.props.location.pathname === '/student/entrance_exam') {
      return this.renderEntranceExam()
    }
    if (this.props.location.pathname.indexOf('school/schedule/auto-trial-course-request') > -1) {
      // 为在线学习申请试听课，CRM专用
      return this.renderAutoTrialCourseRequest()
    }
    if (topLevel === 'student') {
      return this.renderStudent(modules)
    }
    const userTypeId = this.state.userTypeId
    const chatBox = this.getChatBox()
    const chatBoxWidth = this.state.open && userTypeId == 2 ? "600px" : "0px"
    const showRobotIcon = utils.getCookie('showRobotIcon')
    console.log('userTypeId: ' , userTypeId)
    return (
      <LangContext.Consumer>
        {
          (val) => {
            return <Row style={{display: "flex", height: "100%"}}>
              <Col flex="auto" style={{width: "100%"}}>
                <div
                  className='container-index'
                  style={{ paddingTop: topLevel ? 58 : 0 }}
                >
                  { topLevel ? <Header
                    topLevel={topLevel}
                    params={this.props.params}
                    organizations={this.props.organizations}
                    modules={modules}
                    onChangeSite={this.handleChangeSite}
                  /> : null }
                  <div className='main-content'>
                    {this.getPanel(modules)}
                    {/* {React.cloneElement(this.props.children, { filter, query: this.query })} */}
                    <Route path='/platform' component={PlatformContainer} />
                    <Route path='/school' component={SchoolContainer} />
                    {
                      userTypeId == 2 && showRobotIcon ?
                      <div className='ai-chatbox-trigger' onClick={this.toggleChatBox.bind(this)}></div>
                      : null
                    }
                    <EditionUpdate
                      type={'teacher'}
                    />
                  </div>
                  {this.getSideLink()}
                  {this.renderTimeSlotsModal()}
                  {this.state.modal}
                  {this.state.modalForm}
                  {this.state.modalConfirm}
                  {this.state.tip}
                </div>
              </Col>
              <Col flex={chatBoxWidth} className='school-chat'>
                {chatBox}
              </Col>
            </Row>
          }
        }
      </LangContext.Consumer>
    )
  }

  redirect () {
    let self = this
    return function (url, replace) {
      // 支持传locationDesc 对象; 格式: {pathname: 'af',query:{}}
      if (typeof url === 'object') {
        self.props.history.push(url)
        return
      }
      url = url.replace(/#.*/, '')
      var parts = url.split('?')
      var path = parts[0]
      var search = parts[1]
      var query

      if (search) {
        query = {}
        search = decodeURIComponent(search)
        const searchArr = search.split('&')
        let sArr = []
        searchArr.forEach((item, index) => {
          if (item.split('=')[0] == 'redirect') {
            let str = item
            searchArr.slice(index + 1).forEach(i => {
              str += `&${i}`
            })
            sArr = searchArr.slice(0, index).concat(str.split(','))
          }
        })
        if (!sArr.length) {
          sArr = searchArr
        }
        sArr.forEach(item => {
          var arr = item.split('=')
          query[arr[0]] = arr[1]
          if (arr.length > 2) {
            let value = ''
            arr.forEach((i, index) => {
              if (index > 0) {
                if (index < arr.length - 1) {
                  value += `${i}=`
                } else {
                  value += i
                }
              }
            })
            query[arr[0]] = value
          }
        })
      }
      var method = replace ? 'replace' : 'push'
      self.props.history[method]({
        pathname: path,
        query
      })
    }
  }

  refresh () {
    let self = this
    return function () {
      let path = (location.pathname || '/') + (location['query'] || '') + (location.hash || '')
      self.props.history.push('/')
      self.props.history.push(path)
    }
  }

  createModalForm () {
    let self = this

    return function (options) {
      self.setState({
        modalForm: <ModalForm close={() => self.setState({ modalForm: undefined })} {...options} />
      })
    }
  }

  createModal () {
    let self = this
    return function (options) {
      self.setState({
        modal: <Modal
          cancel={() => self.setState({ modal: undefined })}
          {...options}
        />
      })
    }
  }

  createModalConfirm () {
    let self = this

    return function (options) {
      self.setState({
        modalConfirm: <ModalConfirm close={() => self.setState({ modalConfirm: undefined })} {...options} />
      })
    }
  }

  createTip () {
    let self = this

    return function (text, type = 'error') {
      let options = {
        text,
        type
      }

      self.setState({
        tip: <Tip close={() => self.setState({ tip: undefined })} {...options} />
      })
    }
  }

  checkIlleagleAccess () {
    setInterval(() => {
      if (this.isIllegalAccess()) {
        utils.logout()
      }
    }, 1000)
  }

  checkIsInHomePage () {
    return ['/', '/liuxue', '/uskid2018', '/uskid', '/teacher', '/admin', '/student'].includes(this.props.location.pathname)
  }
}

export default withRouter(connect(function (state) {
  var configCompleted = true
  var configs = state.config
  for (var i in configs) {
    if (!configs[i] || !configs[i].completed) {
      configCompleted = false
      break
    }
  }
  if (configCompleted) {
    Enum.loadConfigs(configs)
  }
  return {
    configCompleted,
    organizations: configs.organizations,
    organization: state.common.organization,
    profile: state.profile,
    permission: state.permission,
    timeSlots: state.timeSlots
  }
})(Container))
