// CountdownButton.tsx
import * as React from 'react'
import { useState, useEffect, useRef, useCallback } from 'react';
import { Button } from 'antdv4';

// 自定义防抖 Hook
const useDebounce = (fn: Function, delay: number) => {
  const timerRef = useRef<number | null>(null);

  // 使用 useCallback 缓存防抖函数
  const debouncedFn = useCallback(
    (...args: any[]) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        fn(...args);
      }, delay);
    },
    [fn, delay]
  );

  return debouncedFn;
};


type Props = {
  // 必需属性
  text: string;
  onClick: () => Promise<unknown>;  // 返回 Promise 的点击处理函数
  // 可选属性
  countdown?: number;                     // 倒计时时长（默认60）
  onFailure?: (error: Error) => void;     // 失败回调
  disabled?: boolean;                     // 外部控制禁用
  className?: string;
};

export default ({
  text,
  onClick,
  countdown = 60,
  onFailure,
  disabled: externalDisabled = false,
  className = ''
}: Props) => {
  const [remaining, setRemaining] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const timerRef = useRef<number>();

  // 防抖点击处理（500ms内多次点击只执行最后一次）
  const handleDebouncedClick = useDebounce(() => {
    handleClick();
  }, 500);

  // 清理定时器
  useEffect(() => () => {
    if (timerRef.current) clearInterval(timerRef.current);
  }, []);

  // 开始倒计时
  const startCountdown = () => {
    setRemaining(countdown);
    timerRef.current = window.setInterval(() => {
      setRemaining(prev => {
        if (prev <= 1) {
          clearInterval(timerRef.current!);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  // 处理点击事件
  const handleClick = async () => {
    if (remaining > 0 || isLoading) return;

    try {
      setIsLoading(true);
      //TODO: 根据onClick()返回状态确定是否需要开始计时
      await onClick();      // 执行父组件传入的逻辑
      startCountdown();            // 成功时启动倒计时
    } catch (error) {
      onFailure?.(error as Error); // 失败时通知父组件
    } finally {
      setIsLoading(false);
    }
  };

  // 综合禁用状态
  const internalDisabled = remaining > 0 || isLoading;
  const isDisabled = externalDisabled || internalDisabled;

  return (
    <Button
      type="primary"
      className={className}
      onClick={handleDebouncedClick}
      disabled={isDisabled}
      loading={isLoading}
    >
      {remaining > 0 ? `${remaining}秒后重试` : text}
    </Button>
  );
};
