import * as React from 'react'
import { useState, useEffect } from 'react';
import { Alert } from 'antdv4'

const AutoCloseAlert = ({ message, type, onClose, duration = 2000 }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    // 开始退出动画
    const timer1 = setTimeout(() => {
      setVisible(false)
    }, duration)

    // 父组件回调后延400ms，为退出动画留出展示时间
    const timer2 = setTimeout(() => {
      onClose();
    }, duration+400)

    return () => {
      clearTimeout(timer1)
      clearTimeout(timer2)
    }
  }, []);

  return (
  <div className={`auto-close-alert-container ${visible ? 'enter' : 'exit'}`}>
    <Alert
      message={message}
      type={type}
      showIcon
      className="auto-close-alert"
    />
  </div>
  );
};

export default AutoCloseAlert
