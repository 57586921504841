import * as utils from 'common/utils'
import config from 'common/config'
import * as React from 'react'
import { useState, useEffect } from 'react'
import * as rest from 'common/rest'
import { Layout, Tabs, Button, Card, Modal, Form, Input, Col, Row } from 'antdv4'
import { isLogined, validatePhone, getCookie } from 'common/utils'
import type { Rule } from 'antdv4/es/form'
import AutoCloseAlert from 'components/student/auto_close_alert'
import CountdownButton from 'components/student/countdown_button'
import { getVerificationCode } from 'helpers/sms'
import { signupWithSms } from 'helpers/user'

const { Header, Content } = Layout

const EXAMS = config.entranceExams

interface ValidationResult {
  isValid: boolean;
  message?: string;
}

const validatePhoneWithMessage = (phone: string): ValidationResult => {
  if (!phone) return { isValid: false, message: '手机号不能为空' };
  return validatePhone(phone)
    ? { isValid: true }
    : { isValid: false, message: '请输入有效的11位手机号' };
}

const phoneRules: Rule[] = [
  { required: true, message: '必填字段' },
  () => ({
    validator(_, value) {
      if (validatePhone(value)) return Promise.resolve();
      return Promise.reject(new Error('格式错误'));
    }
  })
];

const EntranceExam = () => {
  const params = utils.getQueryParams()

  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    ccId: parseInt(params.c),
    schoolId: parseInt(params.s),
    ccName: params.cn,
    schoolName: params.sn,
    name: '',
    phone: '',
    smsCode: '',
  });

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [alerts, setAlerts] = useState([])

  const showModal = () => {
    setIsModalOpen(true)
  }

  const hideModal = () => {
    setIsModalOpen(false)
  }

  const handleOk = async () => {
    try {
      const { phone, smsCode, name, ccId, schoolId } = formData
      const res = await signupWithSms(phone, smsCode, name, ccId, schoolId)
      if (res.code) {
        showAlert(`用户验证失败，请重新提交`, 'error')
      } else {
        showAlert('提交成功，请开始测试', 'success')
        hideModal()
      }
    } catch (err) {
      showAlert(`用户验证失败，请重新提交`, 'error')
    }
  }

  const onExamBtnClick = (url) => {
    if (isLogined()) {
      const userId = parseInt(getCookie('userId'), 10)
      const token = getCookie('token')
      const u = `${url}&studentId=${userId}&token=${token}`
      window.open(u, '_blank')
    } else {
      showModal()
    }
  }

  const onSmsCodeBtnClick = async () => {
    const { phone } = formData
    if (validatePhone(phone)) {
      console.log('To send sms code')
      const result = await getVerificationCode(phone)
      if (result.code === 0) {
        showAlert('短信验证码请求成功，请注意查收', 'success')
      } else {
        const msg = `短信验证码请求出错: ${result.code}`
        showAlert(msg, 'error')
        throw new Error(msg)
      }
    } else {
      const msg = `手机号输入有误，请重新输入手机号`
      showAlert(msg, 'error')
      throw new Error(msg)
    }
  }

  const onFormValues = (values) => {
    setFormData(Object.assign({}, formData, values))
  }

  const showAlert = (message, type) => {
    const newAlert = {
      id: Date.now().toString(),
      message,
      type
    }
    setAlerts([...alerts, newAlert])
  }

  const handleAlertClose = (id: string) => {
    setAlerts(alerts.filter(alert => alert.id !== id))
  }

  const handleSmsError = (err) => {
    const newAlert = {
      id: Date.now().toString(),
      message: err.message,
      type: 'error'
    }
    setAlerts([...alerts, newAlert])
  }

  const { schoolName, ccName } = formData
  return (
      <div className='student-entrance-exam-container'>
        <div className='student-entrance-exam-alert-wrapper'>
          {alerts.map(alert => (
            <AutoCloseAlert
              key={alert.id}
              message={alert.message}
              type={alert.type}
              onClose={() => handleAlertClose(alert.id)}
            />
          ))}
        </div>
        <Layout className='student-entrance-exam-layout'>
          <Header className='student-entrance-exam-layout-header'>
            <div className='student-entrance-exam-layout-header-content-wrapper'>
              <div className='student-entrance-exam-layout-header-1'>入学模考</div>
              <div className='student-entrance-exam-layout-header-2'>亲爱的同学，你好！为了更加清楚的了解你目前的水平，为你制定更加精准完善的学习计划，请耐心完成下面的入学模考测试。该测试成绩将作为后续课程以及作业方案的重要依据。请务必认真对待，感谢配合！</div>
            </div>
          </Header>
          <Content className='student-entrance-exam-layout-content'>
            <Tabs
              defaultActiveKey="1"
              tabBarStyle={{ fontSize: 16 }}
            >
            {
              EXAMS.map(exam => (
                <Tabs.TabPane tab={exam.exam} key={exam.exam} className='student-entrance-exam-layout-content-panel'>
                  {exam.content.map(item => (
                    <Card
                      key={item.title}
                      style={{ marginBottom: 24 }}
                      className='student-entrance-exam-layout-content-panel-item'
                    >
                      <div className='panel-item-left'>
                        <div className='panel-item-title'>{item.title}</div>
                        <div className='panel-item-sub1'>建议时长：{item.suggested_time} </div>
                        <div className='panel-item-sub2'>结构：{item.structure}</div>
                      </div>
                      <Button className='panel-item-button' onClick={() => onExamBtnClick(item.url)}>去做题</Button>
                    </Card>
                  ))}
                </Tabs.TabPane>
              ))
            }
            </Tabs>
          </Content>
        </Layout>

        <Modal
          title="基本信息"
          visible={isModalOpen}
          maskClosable={false}
          onCancel={hideModal}
          footer={[]}
          >
          <div className="form-preface">
          请输入你的基本信息后开始进行入学模考测试
          </div>
          <Form
            form={form}
            name='registration_form'
            className='student-entrance-exam-registration'
            layout="vertical"
            onValuesChange={onFormValues}
            initialValues={{
              school: schoolName,
              recommender: ccName,
              }}
              onFinish={handleOk}
          >
            <Form.Item
              name='name'
              label='姓名'
              rules={[{ required: true, message: '请输入姓名'}]}
            >
              <Input placeholder="请输入姓名" />
            </Form.Item>
            <Form.Item
              name='phone'
              label='手机号'
              rules={phoneRules}
            >
              <Row>
                <Col span={18}>
                  <Input placeholder="请输入手机号" />
                </Col>
                <Col span={1} />
                <Col span={5}>
                  <CountdownButton
                    text='发送验证码'
                    className='form-sms-code-button'
                    onClick={onSmsCodeBtnClick}
                    onFailure={(err) => handleSmsError(err)}
                    countdown={60}
                  />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item
              name='smsCode'
              label='验证码'
              rules={[{ required: true, message: '请输入短信验证码'}]}
            >
              <Input placeholder="请输入短信验证码" />
            </Form.Item>
            <Form.Item
              name='recommender'
              label='推荐人'
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              name='school'
              label='推荐校区'
            >
              <Input disabled />
            </Form.Item>
            <Form.Item>
              <Button key="submit" type="primary" htmlType="submit" style={{width:"100%"}}>
                确定
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
  )
}

export default EntranceExam
