import * as rest from 'common/rest'
import { message } from 'antd'

export function pushHistory (props, params) {
  const query = Object.assign({}, props.location.query, params)
  props.history.push({
    pathname: location.pathname,
    query
  })
}

export async function modifyPassword (values, isPhoneLogin, callback) {
  if (!values) return false
  const data: any = {
    password: values.password,
    newPassword: values.newPassword
  }
  if (isPhoneLogin) {
    data.captcha = values.captcha
  }
  const res = await rest.put('/api/user/password', data)
  if (res.code) return message.error({ text: '修改密码失败', msg: res.msg })
  callback && callback()
  return message.success('修改密码成功')
}

export async function signupWithSms (phone: string, code: string, name: string, ccId: number, schoolId: number) {
  const data: any = {
    phone,
    verificationCode: code,
    name,
    courseConsultantId: ccId,
    organizationId: schoolId
  }

  try {
    const res = await fetch('/api/user/student/signup', {
      method: 'POST',
      redirect: 'manual', // 关键配置：禁止自动重定向
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`)
    }

    const result = await res.json()
    return result
  } catch (err) {
    throw new Error(`请求出错: ${err.message}`)
  }
}
