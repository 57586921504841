
/**
 * @file 应用层配置，跨环境有效
 */

module.exports = {
  productionDomain: 'sp.smartstudy.com', // 公司access打点支持的域名
  stagingDomain: 'sp.staging.smartstudy.com', // 公司access打点支持的域名
  devAccessDomain: '', // dev的access打点地址
  productionCDN: 'https://media8.smartstudy.com',
  promotionPid: 'tggr8cw6', // 渠道ID
  adaptationHost: 'https://zsy.smartstudy.com',
  smartDemoOrganizationIds: [1, 34], // 演示用的站点
  trialExams: ['1', '2'],
  toeflPackageIds: [193, 1, 126, 266],
  ieltsPackageIds: [3, 97, 209],
  aiSuperTeacherUrl: 'https://ai-superteacher.smartstudy.com',
}
