import config from 'common/config'
import PERMISSION from './permission'
import intl from 'react-intl-universal'

const data = {
  // 状态
  STATUS: {
    NORMAL: 1,
    ABNORMAL: 2,
    OUTOFDATE: 3
  },

  STAFF_STATUS: {
    NORMAL: 1,
    FROZEN: 2
  },

  BUSINESS: {
    YUPEI: 1,
    USKID: 2,
    LIUXUE: 3,
    USKID2018: 4
  },

  BUSINESS_MAP: {
    1: '语培',
    2: '美小',
    3: '留学',
    4: '美小2018'
  },

  ONE_DAY: 24 * 60 * 60 * 1000,

  NEW_FEATURES: ['smartAdaptation'],

  // 状态
  STATUS_NAME: {
    0: '全部',
    1: '正常',
    2: '冻结',
    3: '已过期'
  },

  ORGANIZATION_TYPE: {
    201: '国际中学',
    202: '教育机构',
    203: '其它'
  },

  DOCUMENT_TYPE: {
    1: '官方练习',
    2: '真题',
    3: '范文',
    4: '机经'
  },

  // 站点
  USER_TYPE: {
    STUDENT: 1,
    TEACHER: 2,
    ADMIN: 3
  },

  ROUND_TASK_TYPES: {
    VIDEO: 1,
    PRACTICE: 2,
    QUESTION: 3,
    DOCUMENT: 4,
    TRIAL: 0, // 预约私教
    INTENSIVE_LISTENING: 6
  },

  CONTRACT_TRANSFER_STATUS: {
    UNHANDLE: 1, // 未处理
    ACCEPT: 2, // 接收
    REJECT: 3, // 驳回
    COMPLETED: 4, // 已经结课
    ASSIGNING_TEACHING_PT: 6, // 待分配教学PT
    FILLING_IN_DETAILS: 7, // 待填写排课须知
    ASSIGNING_SERVICE_PT: 8 // 待分配服务PT
  },

  EXAM_NAME: {
    1: 'toefl',
    2: 'ielts',
    3: 'gre',
    5: 'gmat'
  },

  VIP_COURSE: {
    STATUS: {
      ARRANGED: 1,
      FRONZEN: 2,
      PRE_ARRANGED: 3, // 预排课
      COMPLETED: 4, // 已经上课
      INCOMPLETED: 5 // 未上课
    }
  },

  TRIAL_TIME_STRINGS: [
    '08:30-09:00',
    '09:30-10:00',
    '10:40-11:10',
    '11:40-12:10',
    '13:30-14:00',
    '14:30-15:00',
    '15:40-16:10',
    '16:40-17:10',
    '18:30-19:00',
    '19:30-20:00'
  ],

  COURSE_REQUEST_REASON: {
    TEACHER_BUSINESS: 1,
    TEACHER_PRIVATE: 2,
    STUDENT: 3,
    OTHER: 4
  },

  COURSE_REQUEST_REASON_TEXT: {
    1: '教师因公',
    2: '教师因私',
    3: '学员自身',
    4: '其他'
  },

  // 任务清单科目类型
  CHECKLIST_SUBJECT_NAME: {
    0: '综合',
    1: '听力',
    2: '口语',
    3: '阅读',
    4: '写作',
    5: '词汇',
    6: '导学课',
    7: '基础',
    8: '语法',
    9: '填空',
    10: '数学',
    11: '英语',
    12: '科学推理',
    13: '微积分',
    14: '统计学',
    15: '物理',
    16: '化学',
    17: '微观经济学',
    18: '逻辑',
    19: '句子改错'
  },

  // 考试目的
  EXAM_PURPOSE: {
    1: '工作',
    2: '留学',
    3: '移民',
    4: '其他'
  },

  // 产品经理定义的一些额外的科目映射关系
  CHECKLIST_SUBJECT_NAME_EXTRA: {
    List: 5,
    list: 5,
    作文: 4,
    Argument: 4,
    argument: 4,
    Issue: 4,
    issue: 4
  },

  // 任务清单科目颜色
  CHECKLIST_SUBJECT_COLOR: {
    0: '#000',
    1: '#7DB2C8',
    2: '#5DAEFF',
    3: '#95B93C',
    4: '#39C3CB',
    5: '#0F769F'
  },

  // 任务清单任务类型
  CHECKLIST_TASK_NAME: {
    1: '专家课程',
    2: '真题练习',
    3: '专家面授',
    4: '仿真模考',
    5: '自定义任务',
    6: '单词专项',
    7: '小班面授',
    8: '新班课',
    11: '新班课',
    12: '文章精读',
    13: '精听练习',
    15: '单词测试',
    16: '跟读练习'
  },

  // 任务清单任务类型颜色
  CHECKLIST_TASK_COLOR: {
    1: '#3399FF',
    2: '#65B767',
    5: '#F2478B',
    12: '#F247E5',
    13: '#906DE2',
    15: '#58ACB4'
  },

  WORD_TEST_TYPES: {
    BaseMean: 1,
    ListenMean: 2,
    ListenSpell: 3,
    Speak: 4
  },

  // 性别
  GENDER_NAME: {
    0: '不限',
    1: '男',
    2: '女'
  },
  // 学习规划状态
  PLAN_STATUS: {
    1: '已开课',
    2: '未开课'
  },

  TEACHING_PLANS_STATUS: {
    1: '待提交',
    2: '已提交'
  },

  // 定价状态
  PRICE_STATUS: {
    1: '已定价',
    2: '未定价',
    3: '已冻结'
  },

  // 合同状态
  CONTRACT_STATUS: {
    1: '待分配',
    2: '已分配',
    3: '已冻结'
  },

  // 合同延期
  CONTRACT_POSTPONEMENT: {
    1: '是',
    2: '否'
  },

  // 教师类型
  TEACHER_TYPE: {
    1: 'PT教师',
    2: 'VIP教师',
    3: '专家教师'
  },

  // 面授类型
  TEACHING_TYPE: {
    1: '小班',
    2: '一对一'
  },

  // 面授状态
  TEACHING_STATUS: {
    1: '待审核',
    2: '通过',
    3: '驳回',
    4: '已完成'
  },

  COURSE_REQUEST_STATUS: {
    1: '待处理',
    2: '待排课',
    3: '已驳回',
    4: '已排课',
    5: '已作废'
  },

  COURSE_REQUEST: {
    STATUS: {
      UNHANDLED: 1,
      UNARRANGED: 2,
      REJECTED: 3,
      ARRANGED: 4,
      CANCELLED: 5
    }
  },

  QUESTIONAIRE_STATUS: {
    SENT: 2,
    SUBMITTED: 3,
    EXPIRED: 4
  },

  QUESTIONAIRE_NAME: {
    1: '全托管',
    2: '个性化',
    3: '班课'
  },

  QUESTIONAIRE_TYPE: {
    TRUSTEESHIP: 1,
    PERSONAL_TAILOR: 2,
    BANKE: 3
  },

  // 面授状态
  ABSENCE_STATUS: {
    1: '请假中',
    2: '已销假'
  },

  // 面授状态
  QUESTION_STATUS: {
    1: '未答复',
    2: '已答复'
  },

  CLASSROOM_TYPE: {
    1: '班课',
    2: '一对一面授课',
    3: '班课&一对一面授课'
  },

  COURSE_TYPE: {
    GROUP: 1, // 班课
    VIP: 2, // 面授课
    TRIAL_COURSE: 3 // 试听课
  },

  CLASSROOM_USAGE: {
    GROUP_COURSE: 1,
    VIP_COURSE: 2,
    ALL: 3
  },

  TIME_SLOTS: [
    { slot: 10, name: 'A1' },
    { slot: 20, name: 'A2' },
    { slot: 30, name: 'B1' },
    { slot: 40, name: 'B2' },
    { slot: 50, name: 'C1' },
    { slot: 60, name: 'C2' },
    { slot: 70, name: 'D1' },
    { slot: 80, name: 'D2' },
    { slot: 90, name: 'E1' },
    { slot: 100, name: 'E2' },
    { slot: 110, name: 'F1' },
    { slot: 120, name: 'F2' }
  ],

  TIME_SLOTS_KV: {},

  // 年级配置
  GRADE: {
    '-10': '初中以下',
    1: '初一',
    2: '初二',
    3: '初三',
    4: '高一',
    5: '高二',
    6: '高三',
    7: '大一',
    8: '大二',
    9: '大三',
    10: '大四',
    11: '研一',
    12: '研二',
    13: '研三',
    20: '高四',
    30: '成人',

    // 新CRM年级对应关系
    107: '在职',
    108: '自由',
    106: '博士生',
    161: '博士生-博一',
    162: '博士生-博二',
    163: '博士生-博三',
    167: '博士生-已毕业',
    105: '硕士生',
    151: '硕士生-硕一',
    152: '硕士生-硕二',
    153: '硕士生-硕三',
    157: '硕士生-已毕业',
    104: '大学生',
    141: '大学-大一',
    142: '大学-大二',
    143: '大学-大三',
    144: '大学-大四',
    147: '大学-已毕业',
    103: '高中生',
    131: '高中-高一',
    132: '高中-高二',
    133: '高中-高三',
    137: '高中-已毕业',
    102: '初中生',
    121: '初中-7年级（初一）',
    122: '初中-8年级（初二）',
    123: '初中-9年级（初三）',
    127: '初中-已毕业',
    101: '小学生',
    111: '小学-1年级',
    112: '小学-2年级',
    113: '小学-3年级',
    114: '小学-4年级',
    115: '小学-5年级',
    116: '小学-6年级',
    117: '小学-已毕业'
  },

  // 获取数据选项
  FETCHING_OPTIONS: {
    platform: {
      config: {
        url: '/api/configs',
        default: 'field'
      },

      document: {
        url: '/api/document',
        default: 'id'
      },

      documents: {
        url: '/api/documents'
      },

      organization: {
        url: '/api/organization',
        default: 'id'
      },

      organizations: {
        url: '/api/organizations'
      },

      examinations: {
        url: '/api/config/examinations'
      },

      subjects: {
        url: '/api/config/subjects'
      },

      lovs: {
        url: '/api/config/lovs'
      },

      tags: {
        url: '/api/config/tags'
      },

      holidays: {
        url: '/api/config/holidays'
      },

      crmBranchCompanies: {
        url: '/api/crm/branch/companies'
      },

      admins: {
        url: '/api/admins'
      }
    },

    school: {
      role: {
        url: '/api/role',
        default: 'id'
      },

      roles: {
        url: '/api/roles'
      },

      uskidNewPermissions: {
        url: `${config.url.uskid}/api/user/permission`,
        take: 'data'
      },

      structures: {
        url: '/api/organization/structure'
      },

      staff: {
        url: '/api/organization/staff',
        default: 'id'
      },

      staffs: {
        url: '/api/organization/staffs'
      },

      prices: {
        url: '/api/organization/prices'
      },

      student: {
        url: '/api/student',
        default: 'id'
      },

      students: {
        url: '/api/students'
      },

      teachers: {
        url: '/api/teachers'
      },
      classrooms: {
        url: '/api/classrooms'
      },
      studios: {
        url: '/api/live-classrooms'
      },
      campuses: {
        url: '/api/organization/campuses'
      },

      teacher: {
        url: '/api/teacher',
        default: 'id'
      },

      teaching: {
        url: '/api/teaching',
        default: 'id'
      },

      teachings: {
        url: '/api/teachings'
      },

      absences: {
        url: '/api/absences'
      },

      questions: {
        url: '/api/questions'
      },

      notes: {
        url: '/api/notes'
      },

      contractTransfers: {
        url: '/api/contract-transfers'
      },
      contractTransfer: {
        url: '/api/contract-transfer',
        default: 'id'
      }
    }
  },
  // 权限
  PERMISSION,

  // 权限
  CRM_PERMISSION: {
    student: {
      name: '学员管理',
      operation: {
        create: '添加',
        update: '编辑',
        view: '查看'
      }
    },

    marketActivity: {
      name: '市场活动',
      operation: {
        create: '添加',
        update: '编辑',
        view: '查看',
        approvalLevel1: '一级审批',
        approvalLevel2: '二级审批'
      }
    },

    marketActivityRecord: {
      name: '市场活动明细',
      operation: {
        create: '添加',
        import: '导入',
        view: '查看'
      }
    },

    clue: {
      name: '线索管理',
      operation: {
        create: '创建',
        view: '查看',
        assign: '分配',
        dispatch: '派单',
        shift: '转移',
        abandon: '放弃',
        sendSMS: '短信发送'
      }
    },

    clueMarketAssign: {
      name: '市场部线索分配',
      operation: {}
    },

    clueMarketFollow: {
      name: '市场部线索跟踪',
      operation: {}
    },

    clueOrder: {
      name: '渠道部线索关联订单',
      operation: {
        transfer: '转案'
      }
    },

    clueAssign: {
      name: '分配线索',
      operation: {}
    },

    chance: {
      name: '机会管理',
      operation: {
        create: '添加',
        view: '查看',
        assign: '分配',
        return: '退回',
        visitConfirm: '到店确认',
        shift2Online: '转为线上工单',
        shift2Offline: '转到线下其他学习中心',
        abandon: '放弃'
      }
    },
    chanceDistribute: {
      name: '工单分配',
      operation: {}
    },

    onlineChance: {
      name: '线上工单管理',
      operation: {
        view: '查看',
        create: '创建',
        shift2Offline: '派线下工单',
        archive: '放入藏金阁',
        distribute: '分配',
        createContactRecord: '创建联系记录',
        sendSMS: '短信发送',
        confirmAttendance: '上课确认'
      }
    },

    onlineChanceDistribute: {
      name: '线上工单分配',
      operation: {}
    },

    onlineChanceSigned: {
      name: '已签约工单',
      operation: {}
    },

    reactivatedChance: {
      name: '公池管理',
      operation: {
        view: '查看',
        createContactRecord: '创建联系记录',
        sendSMS: '短信发送'
      }
    },

    archivedStudent: {
      name: '藏金阁',
      operation: {
        view: '查看',
        reactivate: '放入公池'
      }
    },

    onlineChanceOrder: {
      name: '线上工单订单相关',
      operation: {}
    },

    onlineChanceOrderBlank: {
      name: '线上工单空白订单',
      operation: {
        bind: '绑定工单'
      }
    },

    intentionalStudent: {
      name: '高意向学员',
      operation: {}
    },

    contactRecord: {
      name: '联系记录',
      operation: {
        create: '添加',
        view: '查看'
      }
    },

    contract: {
      name: '合同管理',
      operation: {
        create: '添加',
        update: '编辑',
        view: '查看',
        delete: '删除',
        transfer: '转案',
        approvalLevel1: '一级审批',
        approvalLevel2: '二级审批'
      }
    },

    payment: {
      name: '收款管理',
      operation: {
        create: '添加',
        update: '编辑',
        view: '查看',
        delete: '删除',
        approvalLevel1: '一级审批',
        approvalLevel2: '二级审批'
      }
    },

    refund: {
      name: '退款管理',
      operation: {
        create: '添加',
        update: '编辑',
        view: '查看',
        delete: '删除',
        approvalLevel1: '一级审批',
        approvalLevel2: '二级审批',
        approvalLevel3: '三级审批'
      }
    },

    deposit: {
      name: '订金管理',
      operation: {
        create: '添加',
        update: '编辑',
        view: '查看',
        delete: '删除',
        approvalLevel1: '一级审批'
      }
    },

    sms: {
      name: '短信管理',
      operation: {
        create: '添加',
        view: '查看'
      }
    },

    smsTemplate: {
      name: '短信模板管理',
      operation: {
        create: '添加',
        update: '编辑',
        view: '查看'
      }
    },

    recommender: {
      name: '推荐人管理',
      operation: {
        create: '添加',
        update: '编辑',
        view: '查看'
      }
    },

    infoSource: {
      name: '信息来源管理',
      operation: {
        create: '添加',
        update: '编辑',
        view: '查看'
      }
    },

    task: {
      name: '任务额管理',
      operation: {
        create: '添加',
        update: '编辑',
        view: '查看'
      }
    },

    taskOrganization: {
      name: '机构任务额管理',
      operation: {
        create: '添加',
        update: '编辑',
        view: '查看'
      }
    },

    importLog: {
      name: '导入日志',
      operation: {
        view: '查看'
      }
    },

    operationLog: {
      name: '操作日志',
      operation: {
        view: '查看'
      }
    },

    product: {
      name: '商品管理',
      operation: {
        create: '添加',
        update: '编辑',
        view: '查看'
      }
    },

    productTemplate: {
      name: '商品模板管理',
      operation: {
        create: '添加',
        update: '编辑',
        view: '查看'
      }
    },

    dataAccessPermission: {
      name: '数据共享',
      operation: {
        create: '添加',
        update: '编辑',
        view: '查看'
      }
    },

    callCenter: {
      name: '呼叫中心',
      operation: {
        create: '添加',
        update: '编辑',
        view: '查看',
        delete: '删除'
      }
    },

    monthlyReportOfTMK: {
      name: '学习中心TMK月报表',
      operation: {}
    },

    monthlyReportsOfTMK: {
      name: '全国TMK月报表',
      operation: {}
    },

    monthlySummaryReportsOfTMK: {
      name: '全国TMK月汇总报表',
      operation: {}
    },

    monthlyReportOfMarket: {
      name: '学习中心市场月报表',
      operation: {}
    },

    monthlyReportsOfMarket: {
      name: '全国市场月报表',
      operation: {}
    },

    monthlySummaryReportsOfMarket: {
      name: '全国市场月汇总报表',
      operation: {}
    },

    monthlyReportOfSales: {
      name: '学习中心销售月报表',
      operation: {}
    },

    monthlyReportsOfSales: {
      name: '全国销售月报表',
      operation: {}
    },

    monthlySummaryReportsOfSales: {
      name: '全国销售月汇总报表',
      operation: {}
    },

    monthlyReportsOfChannel: {
      name: '全国成单渠道月报表',
      operation: {}
    },

    YearlyReportsOfChannel: {
      name: '全国成单渠道月报表',
      operation: {}
    },

    blankOrder: {
      name: '空白订单绑定',
      operation: {}
    },

    dataImportInterface: {
      name: '数据导入接口服务',
      operation: {
        create: '创建',
        delete: '删除',
        update: '修改',
        view: '查看'
      }
    },

    order: {
      name: '虚拟订单',
      operation: {
        create: '创建',
        view: '查看'
      }
    },

    courseReservation: {
      name: '预约体验课',
      operation: {
        online: '线上工单预约体验课',
        offline: '线下工单预约体验课'
      }
    },

    // 新版crm 菜单权限-----------------------------start
    newContract: {
      name: '预约试听课管理',
      operation: {}
    },
    newHome: {
      name: 'crm2 首页',
      operation: {}
    },
    isCc: {
      name: 'cc 角色',
      operation: {}

    },
    isTmk: {
      name: 'tmk 角色',
      operation: {}
    },
    newMarketActivity: {
      name: 'crm2 明细活动管理',
      operation: {
        deleteCostRecord: 'crm2 市场活动明细费用-作废'
      }
    },
    newMarketActivityRecord: {
      name: 'crm2 商机管理',
      operation: {}
    },
    newTmkClues: {
      name: 'crm2 TMK商机管理',
      operation: {
        view: '查看',
        followUp: '跟进',
        visit: '到访',
        active: '激活'
      }
    },
    newCcClues: {
      name: 'crm2 CC工单管理',
      operation: {
        view: '查看',
        followUp: '跟进',
        visit: '到访',
        active: '激活'
      }
    },
    newOrders: {
      name: 'crm2 订单管理',
      operation: {
        editPaymentInfo: '修改支付信息',
        review: '审核',
        blankOrderView: '查看空白订单',
        invalidOrder: '作废订单',
        refund: '退款按钮',
        genContract: '生成合同',
        viewContract: '查看合同',
        commision: '标记反佣',
        invoice: '发票',
        refreshQrPay: '刷新支付结果'
      }
    },
    newTrialLessonList: {
      name: 'crm2 试听课记录',
      operation: {
      }
    },
    newTrialLessonArrangement: {
      name: 'crm2 试听课排课',
      operation: {
      }
    },
    newTrialLessonStatistic: {
      name: 'crm2 排课统计',
      operation: {
      }
    },
    newTeacherIndex: {
      name: 'crm2 试听课老师主页',
      operation: {
      }
    },
    newSell: {
      name: 'crm2 销售统计',
      operation: {}
    },
    newFinance: {
      name: 'crm2 财务对账',
      operation: {
        paymentReview: '审核对账'
      }
    },
    newDivide: {
      name: 'crm2业绩分成',
      operation: {
        searchOrgFromOthers: '其他机构机构转来',
        searchOrgALL: '所有机构间转移(ALL)',
        searchDealAndDivid: '已成交未分成',
        searchDivideAll: '所有成交／分成状态(ALL)',
        viewRules: '查看业绩',
        createRules: '业绩分成',
        editRules: '修改业绩'
      }
    },
    newDeposit: {
      name: 'crm2 订金',
      operation: {}
    },
    newPayment: {
      name: 'crm2 收款',
      operation: {}
    },
    newRefund: {
      name: 'crm2 退款',
      operation: {}
    },
    newSms: {
      name: 'crm2 已发短信',
      operation: {}
    },
    newSmsTemplate: {
      name: 'crm2 短信模板',
      operation: {}
    },
    newProbeTag: {
      name: 'crm2 测试标签管理',
      operation: {}
    },
    newOperationLog: {
      name: 'crm2 商机日志/MQ日志',
      operation: {}
    },
    newImportLog: {
      name: 'crm2 导入日志',
      operation: {}
    },
    newCallCenter: {
      name: 'crm2 呼叫中心',
      operation: {
        statisticHeader: '通话统计',
        yimiConfig: '配置yimi查询条件'
      }
    },
    newProductTemplate: {
      name: 'crm2 商品模板设置',
      operation: {}
    },
    newProduct: {
      name: 'crm2 商品查看页',
      operation: {}
    },
    newDataImportInterface: {
      name: 'crm2 数据导入接口',
      operation: {}
    },
    newSystemSearch: {
      name: 'crm2 系统管理搜索',
      operation: {}
    },
    newClueAutoAssignConfig: {
      name: 'crm2 商机自动分配配置',
      operation: {}
    },
    newClueAutoAssignRatioConfig: {
      name: 'crm2 分配系数设置',
      operation: {
        orgRatio: '商机分配系数设置TAB',
        ccRatio: '工单分配系数设置TAB'
      }
    },
    newQueryLabelConfig: {
      name: 'crm2 查询标签配置',
      operation: {}
    },
    newDataSource: {
      name: 'crm2 数据来源管理',
      operation: {}
    },
    newContractManagement: {
      name: 'crm2 合同管理',
      operation: {
        create: '创建合同',
        edit: '修改合同',
        review: '审核合同'
      }
    },
    newBindMktToPid: {
      name: 'crm2 映射关系',
      operation: {}
    },
    newCommissionList: {
      name: 'crm2 渠道返佣金管理',
      operation: {
        ccmReview: '业务审核',
        financeReview: '财务审核',
        editFinal: '编辑期末'
      }
    },
    newExchange: {
      name: 'crm2 退换课管理',
      operation: {
        review: '查看',
        audit: '审核'
      }
    },
    newBalance: {
      name: 'crm2 余额管理',
      operation: {
        review: '查看',
        refund: '退款'
      }
    },
    newBudget: {
      name: 'crm2 预算管理',
      operation: {}
    },
    newOrgBudget: {
      name: 'crm2 校区预算管理',
      operation: {}
    },
    newHeadBudget: {
      name: 'crm2 集团预算管理',
      operation: {}
    },
    newBankBindList: {
      name: 'crm2 银行卡转账流水管理',
      operation: {}
    },
    newDataDictionaryConfig: {
      name: 'crm2 数据字典',
      operation: {}
    },
    ddm: {
      name: 'crm2 钉钉消息',
      operation: {
        proscenium: '前台',
        ccm: '咨询顾问主管',
        schoolmaster: '校长',
        tmk: 'TMK',
        tmkm: 'TMK主管'
      }
    },
    dashboard: {
      name: '仪表盘',
      operation: {
        'shujujianbao-xiansuo': '数据简报-线索',
        'shujujianbao-yeji': '数据简报-业绩',
        'shujujianbao-zhuanhualv': '数据简报-转化率',
        'jindu-gailan': '进度概览',
        'xiaoshou-paiming': '销售排名',
        'xiaoshou-qushi': '销售趋势',
        'jigou-paiming': '机构排名',
        'qudao-paiming': '渠道排名',
        'bangzhu-zhongxin': '帮助中心'
      }
    },
    role: {
      name: 'crm2 身份选择',
      operation: {
        isXiaozhang: '校长',
        isTMK: 'isTMK',
        isCC: 'isCC',
        isPT: '服务PT',
        isPTM: 'isPTM',
        isCCM: 'isCCM',
        isTMKM: 'isTMKM'
      }
    },
    temp: {
      name: '临时权限',
      operation: {
        temp1: '临时1',
        temp2: '临时2',
        temp3: '临时3',
        temp4: '临时4',
        temp5: '临时5',
        temp6: '临时6'
      }
    },
    // 新版crm 菜单权限----------------------------end

    ccSearch: {
      name: 'crm2 cc查询标签管理',
      operation: {
        create: '创建查询标签',
        edit: '编辑查询标签',
        del: '删除查询标签'
      }
    },

    tmkSearch: {
      name: 'crm2 tmk查询标签管理',
      operation: {
        create: '创建查询标签',
        edit: '编辑查询标签',
        del: '删除查询标签'
      }
    },

    organizationData: {
      name: 'crm2 查看机构数据的权限',
      operation: {
        seeTmkClue: '查看整个机构的tmk线索数据',
        seeCcClue: '查看整个机构的cc线索数据',
        seeTriallessons: '查看整个机构的试听课',
        seeOrders: '查看整个机构的订单',
        seeLivePoolCcClue: '查看本机构工单公池live线索'
      }
    },
    marketClueData: {
      name: 'crm2 市场活动操作权限',
      operation: {
        seeMarketClue: '查看市场活动'
      }
    },
    clueData: {
      name: 'crm2 线索操作权限',
      operation: {
        receiveTmkClue: '接收tmk分派过来的线索',
        receiveCcClue: '接收cc分派过来的线索',
        createTmkClue: '创建tmk线索',
        distributionTmkClue: '分配tmk线索',
        deliveryTmkClue: '派单tmk线索',
        transferTmkClue: '转移tmk线索',
        createCcClue: '创建cc线索',
        distributionCcClue: '分配cc线索',
        transferCcClue: '转移cc线索',
        kickTmkClue: '踢回tmk线索',
        kickCcClue: '踢回cc线索',
        recommendTmkClue: 'tmk推荐',
        recommendAndDeliveryTmkClue: 'tmk推荐并派单',
        recommendCcClue: 'cc推荐',
        newTrialCourse2: '创建新试听课',
        preOrder: '预单',
        ccNoIntresting: '无意向',
        tmkDead: 'TMKDead',
        viewAdmissionTestResult: '查看入学测试成绩',
        createPrepayment: '收订金',
        viewPhoneNumber: '查看电话号码',
        activeDataSource: '渠道激活',
      }
    }
  },

  // 商品模板类型
  PRODUCT_TEMPLATE_TYPE: {
    个性化定制: 1,
    全程托管: 2,
    全程计划: 3,
    班课: 4,
    IVY: 5,
    精英计划: 6,
    批改服务: 7,
    商务英语: 8,
    其他: 9
  },

  // 商品模板类型
  ATHENE_OFFLINE_PRODUCT_TYPE: {
    全程托管: 1,
    个性定制: 2,
    全托管小班: 3,
    成人无限时: 4,
    试听课: 5,
    高中留学申请: 6,
    本科留学申请: 7,
    研究生留学申请: 8,
    国高直通车: 13,
    全程托管加强: 14,
    全日制带练: 27
  },

  GROUP_COURSE: {
    STATUS: {
      UNPUBLISHED: 1,
      PUBLISHED: 2,
      DELETED: -1
    },
    STATUS_NAME: {
      1: '未发布',
      2: '已发布',
      3: '已删除'
    }
  },

  TASK_TYPES: {
    COURSE: 1, // 视频课
    EXERCISE: 2, // 练习
    VIP_COURSE: 3, // 面授课
    MOKAO: 4, // 模考
    CUSTOM: 5, // 自定义
    ZHUANXIANG: 6, // 单词专项
    GROUP_COURSE: 7, // 班课
    NEW_GROUP_COURSE: 8, // 新班课
    PACKAGE_EXERCISE: 10, // 组合练习
    BANKE: 11, // 最新的班课
    DOC: 12,
    INTENSIVE_LISTENING: 13, // 精听练习
    ENTRANCE_TEST: 14, // 入学评测
    WORD_TEST: 15, // 单词测试
    FOLLOW_UP: 16 // 跟读
  },

  onlineDate: {
    date: '2020-4-28 12:00:00'
  },

  TASK_TYPES_CHN: {
    0: '全部',
    1: '专家课程',
    2: '真题练习',
    3: '1对1直播课',
    4: '模考',
    5: '订制任务',
    6: '单词专项',
    8: '小班直播课',
    11: '小班直播课',
    12: '文章精读',
    13: '精听练习',
    15: '单词测试',
    16: '跟读练习',
    unknown: '未知'
  },

  PRACTICE_STATUS: {
    DOING: 1,
    FINISHED: 2,
    PIGAIING: 3,
    PIGAIED: 4
  },

  STUDENT_TASK_STATUS: {
    UNSTARTED: 1, // 未开始
    UNCOMPLETED: 2, // 未完成
    COMPLETED: 3, // 已完成
    PROCESSING: 4, // 进行中
    PIGAIING: 5, // 批改中
    PIGAIED: 6 // 已批改
  },

  ADAPTATION_TASK_STATUS: {
    1: '未开始',
    2: '未完成',
    3: '已完成',
    4: '进行中'
  },

  STUDENT_TASK_STATUS_CHN: {
    1: '未开始',
    2: '未完成',
    3: '已完成',
    4: '进行中',
    5: '批改中',
    6: '已批改',
    null: '尚未完成'
  },

  DEFAULT_TIMEZONE: 'Asia/Shanghai',

  SMART_ADAPTATION_TASK: {
    TYPE: {
      VIDEO: 1,
      EXERCISE: 2,
      QUESTION: 3,
      ARTICLE: 4,
      ORDER: 5,
      INTENSIVE_LISTENING: 6
    },
    TYPE_NAME: {
      1: '视频',
      2: '练习',
      3: '练习',
      4: '文档',
      6: '精听'
    }
  },

  SMART_ADAPTATION_EXAMS: {
    TOEFL: 1,
    IELTS: 2,
    GRE: 3,
    GMAT: 5
  },

  SMART_PROGRESS_STATUS: {
    1: '落后',
    2: '正常',
    3: '超前'
  },

  EXAMS_CN_EN: {
    总分: 'total',
    阅读: 'reading',
    听力: 'listening',
    口语: 'speaking',
    写作: 'writing',
    数学: 'math',
    语文: 'literature',
    综合推理: 'logic'
  },

  WORD_EXAMS: [
    {
      id: 1,
      name: 'TOEFL'
    },
    {
      id: 2,
      name: 'IELTS'
    },
    {
      id: 3,
      name: 'GRE'
    },
    {
      id: 5,
      name: 'GMAT'
    },
    {
      id: 6,
      name: '新SAT'
    },
    {
      id: 8,
      name: '四级'
    },
    {
      id: 21,
      name: '初中'
    },
    {
      id: 23,
      name: '高中'
    },
    {
      id: 56,
      name: '国际高中'
    },
    {
      id: 13,
      name: 'ACT'
    },
    {
      id: 11,
      name: 'SSAT'
    },
    {
      id: 127,
      name: 'TOEFL JUNIOR'
    }
  ],

  BANKE_STATUS: {
    ARRANGING: 1, // 待排课
    ARRANGED: 2, // 已排课
    STARTED: 3, // 已开课
    EXPIRED: 4, // 已过期
    ASSIGNING_TEACHING_PT: 6, // 待分配教学PT
    DISBANDED: 7, // 已解散
    ASSIGNING_SERVICE_PT: 8 // 待分配服务PT
  },
  BANKE_STATUS_SELECT: () => {
    return {
      1: intl.get('Course To Be Assigned'),
      2: intl.get('Class Course Arranged'),
      3: intl.get('Class In Progress'),
      4: intl.get('Expired'),
      6: intl.get('Tutor To Be Assigned'),
      8: intl.get('Teacher To Be Assigned'),
      7: intl.get('Dissolved')
    }
  },

  BANKE_STUDENT_STATUS: {
    1: '正常',
    2: '已退班'
  },
  BANKE_LIST_STATUS_SELECT: {
    0: '全部',
    1: '已开课',
    2: '未开课',
    3: '已过期'
  },
  BANKE_SERVICE_STATUS: {
    UNSUBMIT: 1,
    SUBMITED: 2
  },
  SERVICE_STATUS_SELECT: {
    1: '未提交',
    2: '已提交'
  },

  STUDY_TASK_COURSE_PRODUCTID: {
    BASIC_COURSE: -1,
    FOREIGN_COURSE: -2
  },
  SMART_TEST_STATUS: {
    UNTEST: 0,
    TESTING: 1,
    TESTED: 2
  },

  PRACTICE_VERSION: {
    OLD: 1,
    NEW: 2
  },
  KNOWLEDGE_POINT_STATUS: {
    EASY: 0,
    COMMONLY: 1,
    HARD: 2
  },

  TOEFL_ID: 1,

  // 签到天数
  CHECKIN_DAYS: {
    ONE: 1,
    TWENTY: 20,
    THIRTY_FIVE: 35,
    FIFTY: 50
  },

  // 最多播报条数
  BROADCAST_NUM: 7,

  // 浏览器兼容版本
  BROWSER_V: {
    IE8: 8,
    CHROME50: 50
  },

  PROGRESS_SUBJECT: {
    阅读: 'reading',
    听力: 'listening',
    口语: 'speaking',
    写作: 'writing',
    填空: 'fill',
    数学: 'math',
    '语法(SC)': 'grammar',
    '逻辑(CR)': 'logic',
    '阅读(RC)': 'reading',
    '综合推理(IR)': 'comprehensive'
  },

  // 这个数组定义的考试id均不能继续/重新练习
  NO_REDO_EXAMS_IDS: [2, 65],

  NO_PIGAI_EXAMS_IDS: [65],

  NEW_EXERCISE_EXAM_IDS: [1, 2, 65],

  EXAM_NAME_MAP : {1: "TOEFL", 2: "IELTS", 3: "GRE", 4: "SAT（旧）", 5: "GMAT", 6: "SAT", 7: "考研", 8: "四级", 9: "英语能力", 10: "留学辅导", 11: "SSAT", 12: "AP", 13: "ACT", 14: "成人技能培训", 15: "高考", 16: "专四", 17: "专八", 18: "日常练习", 19: "SAT2", 20: "六级", 21: "初中", 22: "中考", 23: "高中", 50: "其它", 56: "国际高中", 57: "基础课程", 58: "外教", 59: "论文写作", 60: "PETS", 61: "合作视频", 62: "A-level", 63: "托福Junior", 64: "美国文化", 65: "英语基础能力", 66: "PRETCO", 67: "国学", 68: "大学英语", 69: "AEAS", 70: "讲好中国故事", 71: "VR课程", 72: "文书润色", 73: "GRE sub", 74: "老托福", 75: "美高衔接课", 76: "国际初中", 77: "IGCSE", 78: "Duolingo", 79: "美小同步辅导", 80: "TOEFL ITP", 81: "IB", 124: "风电技术"},
  ADD_KNOWLEDGEPOINT_MODAL_EXAMS: ['TOEFL', 'IELTS', 'GRE', 'GMAT', '风电技术'],
  ADD_COURSE_MODAL_EXAMS: ['TOEFL', 'IELTS', 'GRE', 'GMAT', '新SAT', '考研', '四级', '六级', '英语能力', 'PRETCO', '留学辅导', 'SSAT', 'AP', 'ACT', '职业规划', '高考', '专四', '专八', 'SAT2', '中考', '国学', '国际高中', '讲好中国故事', 'IGCSE', 'A-level', '风电技术', '其它'],
  ADD_PRACTICE_MODAL_EXAMS: ['TOEFL', 'IELTS', 'GRE', 'GMAT', '新SAT', '考研', '四级', '六级', 'ACT', '专四', '高考', '初中', '高中', 'AP', '国际高中', 'TOEFL ITP', 'A-level', 'IGCSE', '风电技术', '机考SAT'],
  ADD_EVALUATION_MODAL_EXAMS: ['TOEFL', 'IELTS', 'GRE', 'GMAT', '新SAT', '英语基础能力', '风电技术'],
  ADD_QUESTIONTYPE_PRACTICE_MODAL_EXAMS: ['TOEFL', 'IELTS', 'GRE', 'GMAT', '新SAT', '考研'],
  ADD_TAG_PRACTICE_MODAL_EXAMS: ['TOEFL', 'IELTS', 'GRE', '新SAT'],
  ADD_IL_PRACTICE_EXAMS: ['TOEFL', 'IELTS'],
  ADD_IL_PRACTICE_SUBJECT: {
    TOEFL: {
      textbook: ['TPO', 'TPO Plus', 'OG', '新托福独家样题'],
      tag: ['听力']
    },
    IELTS: {
      textbook: ['剑桥'],
      tag: ['听力']
    }
  },
  ADD_KNOWLEDGEPOINT_MODAL_SUBJECT: {
    TOEFL: ['阅读', '听力', '口语', '写作'],
    IELTS: ['阅读', '听力', '口语', '写作'],
    GRE: ['填空', '阅读', '写作', '数学'],
    GMAT: ['逻辑(CR)', '语法(SC)', '阅读(RC)', '写作', '数学', '综合推理(IR)'],
    '风电技术': ['主控系统', '偏航系统'],
  },
  ADD_COURSE_MODAL_SUBJECT: {
    TOEFL: ['听力', '阅读', '写作', '口语', '词汇', '导学课'],
    IELTS: ['听力', '阅读', '写作', '口语', '词汇', '导学课'],
    GRE: ['填空', '阅读', '写作', '数学'],
    GMAT: ['逻辑(CR)', '语法(SC)', '阅读(RC)', '写作', '数学', '综合推理(IR)', '导学课'],
    新SAT: ['写作', '阅读', '数学', '语法'],
    考研: ['英语', '政治', '数学', '管理类联考', '经济类联考', '导学课'],
    四级: ['四级写作', '四级听力', '四级阅读', '四级翻译'],
    六级: ['六级写作', '六级听力', '六级阅读', '六级翻译'],
    英语能力: ['英语能力', '新概念', '口语', '词汇', '语法', '商务英语', '旅游英语', '电影英语'],
    留学辅导: ['留学申请'],
    SSAT: ['阅读'],
    AP: ['美国历史', '物理', '统计', '化学', '微观经济学', '微积分', '心理学', '宏观经济学', '人文地理'],
    ACT: ['写作', '科学', '英语'],
    职业规划: ['职业规划'],
    高考: ['英语'],
    专四: ['写作', '导学', '听力', '语言知识', '完形填空', '阅读'],
    专八: ['导学', '听力', '阅读', '写作', '翻译', '改错'],
    SAT2: ['数学'],
    中考: ['英语'],
    国学: ['国学'],
    PRETCO: ['A级'],
    国际高中: ['面试口语'],
    讲好中国故事: ['讲好中国故事'],
    IGCSE: ['全球视野', '物理', '生物', '数学', '化学', '经济学'],
    'A-level': ['生物', '经济', '数学'],
  },
  EXAM_SHOW_ALL_SUBJECT: [50, 124, 196],// 其它， 风电技术, 机考SAT
  ADD_PRACTICE_MODAL_SUBJECT: {
    AP: {
      textbook: ['AP练习题'],
      questiontype: [],
      tag: []
    },
    国际高中: {
      textbook: ['国际高中练习题'],
      questiontype: [],
      tag: []
    },
    TOEFL: {
      textbook: ['回忆机经', 'TPO Plus', '独立写作黄金40题', 'TOEFL核心口语100道', '黄金口语80题', '预测机经', '185题库', 'OG', 'TPO', '新托福独家样题', '托改726', '2025-03-15真题'],
      questiontype: ['听力', '阅读', '写作'],
      tag: ['听力', '阅读', '写作', '口语']
    },
    IELTS: {
      // textbook: ['剑桥', '机经', 'OG', 'EG', '其他', '回忆机经', '王陆807词汇', '雅思同义词'],
      textbook: ['剑桥', '口语题库', 'OG', 'EG', '写作回忆机经', '写作预测机经', '补充真题'],
      questiontype: ['听力', '阅读', '写作', '口语'],
      tag: ['听力', '阅读', '写作', '口语']
    },
    GRE: {
      textbook: ['OG', 'GRE官方题库Issue', 'GRE官方题库Argument', 'GRE官方150题', 'GRE再要你命三千', 'GRE练习题'],
      questiontype: ['填空', '阅读', '数学'],
      tag: ['阅读', '写作']
    },
    GMAT: {
      textbook: ['GMAT官方题库Argument', 'PREP', 'OG', 'GMAT红宝书', 'GMAT练习题'],
      questiontype: ['逻辑(CR)', '语法(SC)', '阅读(RC)', '数学', '综合推理(IR)'],
      tag: []
    },
    新SAT: {
      textbook: ['OG', '13套', 'SAT巴朗', 'SAT精选词汇', 'SAT核心词汇'],
      questiontype: ['阅读', '数学', '语法'],
      tag: ['写作']
    },
    考研: {
      textbook: ['英语（二）真题', '英语（一）真题', '政治真题', '数学真题'],
      questiontype: [],
      tag: []
    },
    四级: {
      textbook: ['四级词汇', '模拟题', '真题'],
      questiontype: [],
      tag: []
    },
    六级: {
      textbook: ['模拟题', '真题'],
      questiontype: [],
      tag: []
    },
    ACT: {
      textbook: ['真题'],
      questiontype: [],
      tag: []
    },
    专四: {
      textbook: ['模拟题', '真题'],
      questiontype: [],
      tag: []
    },
    高考: {
      textbook: ['真题'],
      questiontype: [],
      tag: []
    },
    初中: {
      textbook: ['真题'],
      questiontype: ['英语', '单词', '语法'],
      tag: []
    },
    高中: {
      textbook: ['高中英语', '词汇'],
      questiontype: ['英语', '单词'],
      tag: []
    },
    'TOEFL ITP': {
      textbook: ['ITP样题'],
      questiontype: [],
      tag: []
    },
    'A-level': {
      textbook: ['A-level 真题'],
      questiontype: [],
      tag: []
    },
    'IGCSE': {
      textbook: [
        'IGCSE-Math',
        'IGCSE-Biology',
        'IGCSE-Chemistry',
        'IGCSE-Economics',
        'IGCSE-Physics',
        'IGCSE-Math additional',
        'IGCSE-Geography'
      ],
      questiontype: [],
      tag: []
    },
    风电技术: {
      textbook: ['通用题库'],
      questiontype: [],
      tag: []
    },
  },
  COLLECT_CARD_ACTIVITY_EXAMS: ['1'],

  // 来自CRM的在读状态数据
  CRM_JOB_STATUS: {
    1: '在职',
    2: '在读',
    3: '自由'
  },

  TEACHER_EMPLOYMENT_TYPE: {
    FULLTIME: 1,
    PARTTIME: 2,
    FROM_OTHER_DEPARTMENT: 3
  },

  // 来自CRM的申请期望字段
  CRM_TARGET_LEVEL: {
    100: '常青藤',
    101: '美国大学前30',
    130: '美国大学前50',
    150: '美国大学前80',
    180: '美国大学80后',
    250: '美国寄宿高中前50',
    251: '美国走读高中前50',
    260: '美国寄宿高中非前50',
    261: '美国走读高中非前50',
    9000: '合作项目'
  },

  // 可选的意向专业方向
  CRM_INTENTION_DIRECTS: ['工科', '商科', '理科', '社科', '艺术与人文学科', '农林', '生命科学与医学'],
  // 每页显示多少条
  PAGESIZE: {
    TEN: 10,
    FIFTEEN: 15
  },
  SCORE_STATUS: {
    1: '待归档',
    2: '已归档',
    3: '已作废',
    4: '待提交'
  },
  SCORE_STATUS_TEXT: {
    UNFILE: 1,
    FILED: 2,
    CANCELLED: 3,
    SUBMIT: 4
  },
  // 成绩分类
  SCORE_TYPE: {
    1: '入学成绩',
    2: '模考成绩',
    3: '实考成绩',
    4: '国高成绩'
  },

  WEBSOCKET_MSG: {
    join: 'websocket:join',
    leave: 'websocket:leave'
  },

  SECKILL_TYPE: {
    NINE_YUAN: 1,
    EIGHT_YUAN: 2
  },
  CAMP_STATUS: {
    OFF_LINE: 1, // 未上线
    REGISTERING: 2, // 报名中
    NOT_START: 3, // 待开营
    CAMPING: 4, // 开营中
    END: 5, // 已结束
    FULL: 6 // 已报满
  },
  // 教师所属考试组
  EXAMINATION_GROUP: {
    TOEFL: 1,
    IELTS: 2,
    GRE_AND_GMAT: 3,
    SAT: 4,
    OTHER: 5
  },
  GOODS_STATUS: {
    VIP: '一对一',
    BANKE: '班课',
    OTHER: '其他'
  },

  MOST_COMMONLY_USED_EXAMS: [
    {
      id: 1,
      name: '托福',
      english: 'TOEFL'
    },
    {
      id: 2,
      name: '雅思',
      english: 'IELTS'
    },
    {
      id: 3,
      name: 'GRE',
      english: 'GRE'
    },
    {
      id: 5,
      name: 'GMAT',
      english: 'GMAT'
    },
    {
      id: 6,
      name: '新SAT',
      english: 'SAT'
    }
  ],
  WORDS_CORRECT: {
    'jiben_ciyi_zhengquelv': '基本词义正确率',
    'pinxie_zhengquelv': '拼写正确率',
    'tingyin_bianyi_zhengquelv': '听音辩义正确率'
  },
  MOKAO: {
    read_avg_score: 'Reading',
    listen_avg_score: 'Listening',
    speak_avg_score: 'Speaking',
    write_avg_score: 'Writing'
  },
  CLASS_OVERVIEW: {
    'renwushu': '任务数(含测评)',
    'renwu_wanchenglv': '任务完成率',
    'xuesheng_zong_shiyong_shichang': '学习总使用时长',
    'pingjun_meiren_xuexi_shichang': '平均学习时长'
  },
  DANXIANG_OVERVIEW: {
    'renwushu': '任务数',
    'renwu_wanchenglv': '任务完成率',
    'xuesheng_xuexi_shichang': '学生学习时长',
    'pingjun_meiren_xuexi_shichang': '平均每人学习时长',
    'danci_renwushu': '单词任务数',
    'danci_renwu_wanchenglv': '单词任务完成率',
    'danci_zongshu': '单词总数',
    'pingjun_daduishu': '平均答对数',
    'pingjun_dacuoshu': '平均答错数',
    'pingjun_weidashu': '平均未答数',
    'buzhi_renwu_xuexi_shichang': '布置任务学习时长',
    'avg_score': '测评平均分',
    'avg_study_duration_sec': '平均每人学习时长',
    'banke_task_num': '测评数',
    'finish_rate': '测评完成率',
    'study_duration_sec': '学生学习总时长',
  }
}

// convert TIME_SLOTS array to key value object
data.TIME_SLOTS.forEach(el => {
  data.TIME_SLOTS_KV[el.slot] = el
})

export default data
